export const setupStrings = async () => {
  if (
    ["projects#edit_localize"].includes(
      document.querySelector("body")?.dataset?.controllerAction,
    )
  ) {
    console.log("Running setupStrings")
    const stringsSections = document.querySelectorAll(
      "#strings button.toggle-section",
    )
    for (const s of stringsSections) {
      const iconSelectorOpen = document.querySelector(`#strings #io-${s.id}`)
      const iconSelectorClose = document.querySelector(`#strings #ic-${s.id}`)
      const sectionSelector = document.querySelector(`#strings #c-${s.id}`)
      s.addEventListener("click", () => {
        console.log(s.id, iconSelectorOpen, iconSelectorClose, sectionSelector)
        iconSelectorOpen?.classList.toggle("hidden")
        iconSelectorClose?.classList.toggle("hidden")
        sectionSelector?.classList.toggle("hidden")
      })
    }
  }
}
